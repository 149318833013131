const config = {
  apiKey: "AIzaSyBGYs0_EXoTo6rQ5z4swVzhg5h2u-vdMEA",
  authDomain: "alimi-91b6f.firebaseapp.com",
  databaseURL: "https://alimi-91b6f.firebaseio.com",
  projectId: "alimi-91b6f",
  storageBucket: "alimi-91b6f.appspot.com",
  messagingSenderId: "191145405951",
  appId: "1:191145405951:web:765a944f3d69d950"
}

export { config }