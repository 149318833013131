import React, { useContext, useEffect, useReducer, useState } from 'react';
import { FirebaseContext } from './firebase';
import { UserContext } from './reducer/userReducer';
import { Link, Route } from 'react-router-dom';
import Info from './Info';
import Alimi from './Alimi';

function App() {
  const { firebase, firestore } = useContext(FirebaseContext);
  const { userState, userDispatch } = useContext(UserContext);
  const [ loading, SetLoding ] = useState(false);
  const onClickHandler = () => {
    firebase.doSignInWithGoogle()
      .then(result => {
        firestore.collection('users').doc(result.user.uid).get().
          then(doc => {
            if (doc.exists)//문서가 존재한다면
              userDispatch({ type: 'LOGIN', payload: { ...doc.data() } });
            else {
              firestore.doc('users/' + result.user.uid).set({
                uid: result.user.uid,
                email: result.user.email,
                url: null,
                id: null,
                password: null,
                alimi_id: null,
                phoneList: [],
                phone: null
              })
            }
          }).then(r => userDispatch({
            type: 'LOGIN', payload: {
              uid: result.user.uid,
              email: result.user.email,
              url: null,
              id: null,
              password: null,
              alimi_id: null,
              phoneList: [],
              phone: null
            }
          }))
          .catch(e => {
            console.log(e);
          })
      })
      .catch(e => { console.log('로그인 에러', e) })
  }

  const logout = () => {
    firebase.doSignOut().then(() => {
      userDispatch({ type: 'LOGOUT' });
    })
  }

  useEffect(() => {
    if (userState&&userState.uid !== null) {
      console.log('tt');
      SetLoding(true);
    }
  }, [userState])

  return (<>
    <div className="container is-fluid" style={{ backgroundColor: '#2a2a2a' }}>
      <nav className="level is-fullwidth" >
        <p className="level-item has-text-centered">
          {loading && <Link to="/info"><button className="button class">기본정보</button></Link>}
        </p>
        <p className="level-item has-text-centered">
          {loading && <Link to="/alimi"><button className="button">알림장&홈페이지</button></Link>}
        </p>
        <p className="level-item has-text-centered">
          {loading === false && <button className="button is-success" onClick={onClickHandler}>로그인</button>}
          {loading && <button className="button is-danger" onClick={logout}>로그아웃</button>}
        </p>
      </nav>
    </div>
    <div className="container is-fluid">
      {loading && <>
        {userState &&
          <Route path="/info"
            render={props => <Info firestore={firestore} userDispatch={userDispatch}uid={userState.uid} {...props}></Info>}></Route>}
        {userState &&
          <Route path="/alimi"
            render={props => <Alimi firestore={firestore} userState={userState} {...props}></Alimi>}></Route>}
      </>}
    </div>
  </>
  );
}

export default App;
