import React, { useReducer } from 'react';
import { firebase } from '../firebase';

const initialState = {
    uid: null,
    email: null,
    url: null,
    alimi_id: null,
    home_id: null,
    phone: null,
    sendList: [],
    password: null,
}
const UserContext = React.createContext(null);

let dispatcher;
firebase.auth.onAuthStateChanged(user => {
    if (user !== null) {
        // console.log('user:', user);
        firebase.firestore.collection('users').doc(user.uid).get()
            .then(doc => {
                const userData = doc.data();
                firebase.firestore.collection('apis').doc('cbe_alimi').get().then(doc2 => {
                    const { alimi, home } = doc2.data();
                    dispatcher({ type: 'LOGIN', payload: { ...userData, alimi, home } });
                })
            })

    }
    else {
        dispatcher({ type: 'LOGOUT' });
    }
})
const UserContextProvider = (props) => {
    const [state, dispatch] = useReducer(userReducer, initialState);
    dispatcher = dispatch;
    return <UserContext.Provider value={{ userState: state, userDispatch: dispatch }}>
        {props.children}
    </UserContext.Provider>
}
const userReducer = (state, action) => {
    const { type, payload } = action;
    let newState = {}
    switch (action.type) {
        case 'LOGIN':
            newState = { ...state, ...payload };
            break;
        case 'LOGOUT':
            newState = null;
        case 'UPDATE':
            newState = { ...state, ...payload };
            break;
        default:
            newState = { ...state };
            break;
    }
    // console.log('reducer', newState);
    return newState;
}

export { UserContext, UserContextProvider }

