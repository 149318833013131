import React, { useEffect, useRef, useState } from 'react';

const Alimi = (props) => {
  const titleRef = useRef('');
  const messageRef = useRef('');
  const [fSize, setFsize] = useState(5);
  const [home, setHome] = useState(true);
  const [alimi, setAlimi] = useState(true);
  const [loadingHome, setLoadingHome] = useState(false);
  const [loadingAlimi, setLoadingAlimi] = useState(false);
  const [errorHome, setErrorHome] = useState(false);
  const [errorAlimi, setErrorAlimi] = useState(false);

  const firestore = props.firestore;
  const userState = props.userState;
  useEffect(() => {
    const date = new Date();
    let str = date.getMonth() + 1 + "월 ";
    str += date.getDate() + "일 ";
    const day = ['일', '월', '화', '수', '목', '금', '토']
    str += day[date.getDay()] + "요일 알림장";
    titleRef.current.value = str;
    messageRef.current.value = str;
    console.log(userState);
  }, [props.userState])

  const onSubmit = () => {
    setLoadingHome(prev => !prev);
    setLoadingAlimi(prev => !prev);
    const message = messageRef.current.value;
    const title = titleRef.current.value;

    const data = {
      coala: "coalaakstp",
      title: title,
      content: message,
      id: userState.id,
      password: userState.password,
      url: userState.url,
    }
    const alimiData = {
      coala: "coalaakstp",
      title: title,
      content: message,
      alimi_id: userState.alimi_id,
      alimi_sendList: userState.phoneList[0],
      alimi_reply: userState.phone,
      alimi_sendType: "TEXT",
    }
    // var parameter = {
    //      coala:'coalaakstp',
    //      alimi_id:'talksis1',
    //      title : '테스트',
    //      content:"테스트ds메시지",
    //      alimi_sendList:'0107700824501075458245',
    //      alimi_reply:'01077008245',
    //      alimi_sendType:'TEXT',
    //    }
    if (home)
      fetch(userState.home, {
        method: 'POST',
        body: JSON.stringify(data)
      }).then(r => r.json()).then(t => {
        console.log(t);
        if (t.status === 'success') {
          setHome(false);
          setLoadingHome(prev => !prev);
          setErrorHome(false);
        } else {
          setErrorHome(true);
          setLoadingHome(prev => !prev);
        }
      }).catch(e => {setErrorHome(true);setLoadingHome(prev=>!prev)});
    else setLoadingHome(prev => !prev);

    if (alimi)
      fetch(userState.alimi, {
        method: 'POST',
        body: JSON.stringify(alimiData)
      }).then(r => r.json()).then(t => {
        console.log(t);
        if (t.status === true) {
          setAlimi(false);
          setLoadingAlimi(prev => !prev);
          setErrorAlimi(false);
        } else {
          setErrorAlimi(true);
          setLoadingAlimi(prev => !prev);
        }
      }).catch(e => {setErrorAlimi(true);setLoadingAlimi(prev => !prev);});
    else setLoadingAlimi(prev => !prev);
  }

  return <>
    <div className="field has-addons">
      <div className="" style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
        <input type="text" id='title' className="input" ref={titleRef} style={{ flex: 6, marginRight: '1rem' }} ></input>
        <div style={{ flex: 2 }}>
          <label className="checkbox">
            <input type="checkbox" checked={home} onChange={() => setHome(prev => !prev)}></input>
            홈페이지
        </label>
        </div>
        <div style={{ flex: 2 }}>
          <label className="checkbox">
            <input type="checkbox" checked={alimi} onChange={() => setAlimi(prev => !prev)}></input>
            알리미(문자)
        </label>
        </div>
      </div>
    </div>
    {loadingHome && home && <div className="notification is-info" style={{ padding: '0.2rem', margin: '.2rem' }}>
      홈페이지에 전송중입니다.</div>}
    {loadingAlimi && alimi && <div className="notification is-primary" style={{ padding: '0.2rem', margin: '.2rem' }}>
      알리미에 전송중입니다.</div>}
    {errorHome && <div className="notification has-text-centered" style={{ backgroundColor: '#3f3f3f', padding: '.2rem', margin: '.2rem' }}>
      <button className="button is-danger is-outlined" onClick={() => {
        setErrorHome(false); setHome(true);onSubmit();
      }}>홈페이지 전송이 실패했습니다! 다시 전송 하시겠습니까?</button>
    </div>}
    {errorAlimi && <div className="notification has-text-centered" style={{ backgroundColor: '#3f3f3f', padding: '.2rem', margin: '.2rem' }}>
      <button className="button is-danger is-outlined" onClick={() => {
        setErrorAlimi(false);setAlimi(true); onSubmit();
      }}>알리미 전송이 실패했습니다! 다시 전송 하시겠습니까?</button>
    </div>}
    <div className="hero is-fullheight" style={{ display: 'flex' }}>
      <div className=" field has-addons" style={{ display: 'flex', flex: 1 }}>
        <div className="control " style={{ flex: 20, display: 'flex', alignItems: 'stretch' }}>
          <textarea className="" ref={messageRef} style={{ height: '100%', width: '100%', fontSize: fSize + 'rem' }} ></textarea>
        </div>
        <div className="control" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <button className="button is-success" style={{ flex: 1, fontSize: '1rem' }} onClick={() => setFsize(prev => prev + 1)}>+</button>
          <button className="button is-primary" style={{ flex: 1, fontSize: '1rem' }} onClick={onSubmit}>등록</button>
          <button className="button is-danger" style={{ flex: 1, fontSize: '1rem' }} onClick={() => setFsize(prev => prev - 1)}>-</button>
        </div>
      </div>
    </div>
  </>
}

export default Alimi;