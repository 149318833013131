import React from 'react';
import { Firebase } from './firebase';

const FirebaseContext = React.createContext(null);
const firebase = new Firebase();
const FirebaseProvider = (props) => {
    return <FirebaseContext.Provider value={
        {
            firebase: firebase,
            auth: firebase.auth,
            firestore: firebase.firestore,
        }}>
        {props.children}
    </FirebaseContext.Provider>
}

export { FirebaseContext, FirebaseProvider, firebase };