import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { config } from '../firebase_config';


class Firebase {
    constructor() {
        app.initializeApp(config);
        this.firestore = app.firestore();
        this.auth = app.auth();
        this.app = app;
    }
    doSignInWithGoogle = () => {
        const provider = new this.app.auth.GoogleAuthProvider();
        //구글로 로그인 하면
        return this.auth.signInWithPopup(provider)
    }
    doSignOut = () => {
        return this.auth.signOut()
    }
}

export { Firebase };