import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { FirebaseProvider } from './firebase'
import { UserContextProvider } from './reducer/userReducer';
import { BrowserRouter as Router } from 'react-router-dom';

ReactDOM.render(
    <FirebaseProvider>
        <UserContextProvider>
            <Router>
                <App />
            </Router>
        </UserContextProvider>
    </FirebaseProvider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
