import React, { useState, useReducer, useEffect } from 'react';

const INITIAL = 'INITIAL';
const INPUT_CHAGE = 'INPUT_CHANGE';

const Info = (props) => {
  const firestore = props.firestore;
  //필수적으로 있어야 하는 것들을 리듀서 초기화로 사용해야 한다.
  const [loading, setLoading] = useState(false);
  const [phoneList, setPhoneList] = useState('');
  const [modal, setModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    uid: null,
    email: null,
    url: null,
    id: null,
    password: null,
    alimi_id: null,
    phoneList: [],
    phone: null,

  });

  useEffect(() => {
    firestore.collection('users').doc('NScxtt0wcITqD0ONtb1gyX3QxEy2').get()
      .then(doc => {
        dispatch({ type: INITIAL, payload: doc.data() });
        setLoading(true);
      })
  }, [])

  useEffect(() => {
    const list = [...state.phoneList];
    setPhoneList(list.join(''));
  }, [state.phoneList]);
  const inputChangeHandler = (e) => {
    dispatch({
      type: INPUT_CHAGE, payload: {
        id: e.target.id,
        value: e.target.value
      }
    });
  }
  const onSubmit = () => {
    setUpdate(true);
    firestore.collection('users').doc(state.uid).set({
      url: state.url,
      id: state.id,
      password: state.password,
      alimi_id: state.alimi_id,
      phone: state.phone,
      phoneList: [phoneList],
    }, { merge: true })
      .then(() => {
        props.userDispatch({
          type: 'UPDATE', payload: {
            url: state.url,
            id: state.id,
            password: state.password,
            alimi_id: state.alimi_id,
            phone: state.phone,
            phoneList: [phoneList],
          }
        })
        setUpdate(false);
      })

    // uid: null,
    // email: null,
    // url: null,
    // alimi_id: null,
    // home_id: null,
    // phone: null,
    // sendList: [],
    // password: null,
    setModal(true);
  }
  return <div>
    {state.phone !== null &&
      <div className="box" style={{ marginTop: '1rem' }}>
        <div className="field">
          <div className="field has-addons" style={{ display: 'flex' }}>
            <div className="control has-text-centered" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>홈페이지 아이디</div>
            <div className="control " style={{ flex: 1 }}>
              <input className="input" type="text" value={state.id} id="id" onChange={inputChangeHandler}></input>
            </div>
          </div>
        </div>
        <div className="field">
          <div className="field has-addons" style={{ display: 'flex' }}>
            <div className="control has-text-centered" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>홈페이지 패스워드</div>
            <div className="control " style={{ flex: 1 }}>
              <input className="input" type="password" value={state.password} id="password" onChange={inputChangeHandler}></input>
            </div>
          </div>
        </div>
        <div className="field">
          <div className="field has-addons" style={{ display: 'flex' }}>
            <div className="control has-text-centered" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>URL</div>
            <div className="control " style={{ flex: 1 }}>
              <input className="input" type="text" value={state.url} id="url" onChange={inputChangeHandler}></input>
            </div>
          </div>
        </div>
        <div className="field">
          <div className="field has-addons" style={{ display: 'flex' }}>
            <div className="control has-text-centered" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>알리미 아이디</div>
            <div className="control " style={{ flex: 1 }}>
              <input className="input" type="text" value={state.alimi_id} id="alimi_id" onChange={inputChangeHandler}></input>
            </div>
          </div>
        </div>
        <div className="field">
          <div className="field has-addons" style={{ display: 'flex' }}>
            <div className="control has-text-centered" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>알리미 전송 휴대폰</div>
            <div className="control " style={{ flex: 1 }}>
              <input className="input" type="text" placeholder="숫자만 입력하세요" value={state.phone} id="phone" onChange={inputChangeHandler}></input>
            </div>
          </div>
        </div>
        <div className="field">
          <div className="field has-addons" style={{ display: 'flex' }}>
            <div className="control has-text-centered" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>알리미 수신 휴대폰</div>
            <div className="control " style={{ flex: 1 }}>
              <input className="input" type="number" placeholder="숫자만 입력하세요" value={phoneList} id="phoneList" onChange={inputChangeHandler}></input>
            </div>
          </div>
        </div>
        <div className="field has-text-centered">
          <button className="button is-success" onClick={onSubmit}>기본정보 등록</button>
        </div>
      </div>
    }
    <div className={modal ? 'modal is-active' : 'modal'}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="box" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div className="has-text-centered">
            {update ? <>
              <p className="title is-4">업데이트 중...</p>

              <progress className="progress is-small is-success" max="100">15%</progress>
            </> :
              <span className="icon is-large has-text-info" visible={false}>
                <i className={update ? "fas fa-check animated bounceIn slow" : "fas fa-check"} style={{ fontSize: '5rem' }}></i>
              </span>}
          </div>
          {!update && <button
            className="button is-info is-outlined"
            style={{ marginTop: '1rem' }}
            onClick={() => props.history.push('/alimi')}>알림장 쓰러가기</button>}
        </div>
      </div>
      <button className="modal-close is-large" onClick={() => setModal(false)}></button>
    </div>
  </div>
}






const reducer = (state, action) => {
  const { type, payload } = action;
  let newState = {};
  switch (type) {
    case INITIAL:
      newState = { ...state, ...payload };
      break;
    case INPUT_CHAGE:
      newState = { ...state, [payload.id]: payload.value }
      break;
  }
  console.log('INFO reducer', newState);
  return newState
}
export default Info;